import React from "react"
import NextLink from "next/link"

const Link = ({ children, onClick, external, as, to, ...restPprops }) =>
  external ? (
    <a
      href={to}
      onClick={onClick}
      {...restPprops}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <NextLink
      href={to}
      as={as}
      onClick={onClick}
      rel="noopener noreferrer"
      {...restPprops}
    >
      {children}
    </NextLink>
  )

export default Link
