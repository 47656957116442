import styles from "./LoginForm.module.scss"
import React, { useState, useEffect } from "react"
import cx from "clsx"
import { motion } from "framer-motion"
import { useRouter } from "next/router"
import { signIn } from "next-auth/react"
import { toast } from "react-hot-toast"

import PasswordField from "../PasswordField/PasswordField"
import EmailField from "../EmailField/EmailField"
import Button from "../Button/Button"
import Text from "../Text/Text"
import Link from "../Link/Link"

export const handleSignIn = async ({ email, password }) => {
  let auth = await signIn("credentials", {
    email,
    password,
    callbackUrl: "/dashboard/home",
    redirect: false,
  })

  return auth
}

const LoginForm = ({ switchForm, redirectUrl }) => {
  const router = useRouter()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [hasEmailError, setHasEmailError] = useState(false)
  const [hasPasswordError, setHasPasswordError] = useState(false)

  const [hasCredentialError, setHasCredentialError] = useState(false)

  const [limitReached, setLimitReached] = useState(false)

  const handleSubmitLoginForm = async (event) => {
    event.preventDefault()

    setHasEmailError(false)
    setHasPasswordError(false)

    if (email && password) {
      setLoading(true)

      let { status, ok, error } = await handleSignIn({ email, password })

      if (error && ok && status === 200) {
        console.log({ error, status })
        setLoading(false)
        setLimitReached(true)
        return
      }

      if (error && !ok && status === 401) {
        console.log({ error, status })
        setHasCredentialError(true)
        setLoading(false)
        return
      }

      if (ok && !error && status === 200) {
        if (redirectUrl) {
          if (redirectUrl === "current") {
            router.push(
              { pathname: router?.pathname, query: router?.query },
              undefined,
              {
                shallow: false,
                scroll: true,
              }
            )
            return
          }
          router.push(redirectUrl, undefined, {
            shallow: false,
            scroll: true,
          })
          return
        }

        router.push("/dashboard/home", undefined, {
          shallow: false,
          scroll: true,
        })
        return
      }
    }
  }

  useEffect(() => {
    return () => {
      setLimitReached(false)
    }
  }, [])

  return (
    <>
      {limitReached && (
        <div className={styles.limit}>
          <p className={styles.limitText}>
            Terlalu banyak percobaan login! Silakan coba lagi dalam 15 menit.
          </p>
        </div>
      )}
      <motion.form
        className={styles.loginForm}
        onSubmit={handleSubmitLoginForm}
        initial={{ x: 1000 }}
        animate={{ x: 0 }}
        exit={{ x: -1000 }}
        transition={{ duration: 0.12, ease: "easeOut" }}
      >
        {hasCredentialError && (
          <div className={styles.limit}>
            <p className={styles.limitText}>
              Email atau password salah, silakan ulangi.
            </p>
          </div>
        )}
        <EmailField
          label="Email"
          id="user-login-email"
          value={email}
          setValue={setEmail}
          disabled={loading}
          setHasError={setHasEmailError}
          autoFocus
        />
        {/* <input type="hidden" value={} /> */}
        <PasswordField
          id="user-current-password"
          label="Password"
          value={password}
          setValue={(e) => setPassword(e.currentTarget.value)}
          disabled={loading}
          forgotPassword
          setHasError={setHasPasswordError}
        />
        <Button
          as="submit"
          primary
          large
          stretch
          disabled={
            !email || !password || loading || hasEmailError || hasPasswordError
          }
          loading={loading}
          className={styles.cta}
        >
          Login
        </Button>
        <div className={styles.alternative}>
          <Text small as="p" align="center">
            Belum punya akun?{" "}
          </Text>
          {switchForm ? (
            <Button primaryText small onClick={switchForm}>
              Buat akun baru
            </Button>
          ) : (
            <Link to="/register">Buat akun baru</Link>
          )}
        </div>
      </motion.form>
    </>
  )
}

export default LoginForm
