import styles from "./EmailField.module.scss"
import React, { useEffect, useState } from "react"
import cx from "clsx"

import Text from "../Text/Text"

const isEmail =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

const EmailField = ({
  setHasError,
  id,
  name,
  label,
  secondaryLabel,
  className,
  value,
  hint,
  setValue,
  autoFocus,
  placeholder,
  disabled,
  ...restProps
}) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }, [show])

  const handleFocus = () => {
    setShow(false)
  }

  const handleBlur = () => {
    setShow(!isEmail.test(value))
  }

  return (
    <div className={cx(styles.root)} {...restProps}>
      <Text as="label" label htmlFor={id} className={styles.label}>
        {label}{" "}
        {secondaryLabel && (
          <span className={styles.secondaryLabel}>{secondaryLabel}</span>
        )}
      </Text>
      <input
        className={cx(styles.field, { [styles.error]: show })}
        type="email"
        id={id}
        name={name || id}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        onChange={(e) => setValue(e.currentTarget.value)}
        autoFocus={autoFocus}
        disabled={disabled}
      />
      {show && (
        <p className={cx(styles.error, styles.hint)}>Email tidak valid</p>
      )}
      {hint && <p className={styles.hint}>{hint}</p>}
    </div>
  )
}

export default EmailField
